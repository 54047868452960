import BigNumber from 'bignumber.js'
import { BIG_ONE, BIG_ZERO } from '../../utils/bigNumber'
import { filterFarmsByQuoteToken } from '../../utils/farmsPriceHelpers'
import { SerializedFarm } from '../../state/types'
import tokens from '../../config/constants/tokens'

const getFarmFromTokenSymbol = (
    farms: SerializedFarm[],
    tokenSymbol: string,
    preferredQuoteTokens?: string[],
): SerializedFarm => {
    const farmsWithTokenSymbol = farms.filter((farm) => farm.token.symbol === tokenSymbol)
    const filteredFarm = filterFarmsByQuoteToken(farmsWithTokenSymbol, preferredQuoteTokens)
    return filteredFarm
}

const getFarmBaseTokenPrice = (
    farm: SerializedFarm,
    quoteTokenFarm: SerializedFarm,
    bnbPriceBusd: BigNumber,
): BigNumber => {
    const hasTokenPriceVsQuote = Boolean(farm.tokenPriceVsQuote)

    if (farm.quoteToken.symbol === tokens.busd.symbol) {
        return hasTokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote as string) : BIG_ZERO
    }

    if (farm.quoteToken.symbol === tokens.wbnb.symbol) {
        return hasTokenPriceVsQuote ? bnbPriceBusd.times(farm.tokenPriceVsQuote as string) : BIG_ZERO
    }

    // We can only calculate profits without a quoteTokenFarm for BUSD/BNB farms
    if (!quoteTokenFarm) {
        return BIG_ZERO
    }

    // Possible alternative farm quoteTokens:
    // UST (i.e. MIR-UST), pBTC (i.e. PNT-pBTC), BTCB (i.e. bBADGER-BTCB), ETH (i.e. SUSHI-ETH)
    // If the farm's quote token isn't BUSD or WBNB, we then use the quote token, of the original farm's quote token
    // i.e. for farm PNT - pBTC we use the pBTC farm's quote token - BNB, (pBTC - BNB)
    // from the BNB - pBTC price, we can calculate the PNT - BUSD price
    if (quoteTokenFarm.quoteToken.symbol === tokens.wbnb.symbol) {
        const quoteTokenInBusd = bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote as string)
        return hasTokenPriceVsQuote && quoteTokenInBusd
            ? new BigNumber(farm.tokenPriceVsQuote as string).times(quoteTokenInBusd)
            : BIG_ZERO
    }

    if (quoteTokenFarm.quoteToken.symbol === tokens.busd.symbol) {
        const quoteTokenInBusd = quoteTokenFarm.tokenPriceVsQuote
        return hasTokenPriceVsQuote && quoteTokenInBusd
            ? new BigNumber(farm.tokenPriceVsQuote as string).times(quoteTokenInBusd)
            : BIG_ZERO
    }

    // Catch in case token does not have immediate or once-removed BUSD/WBNB quoteToken
    return BIG_ZERO
}

const getFarmQuoteTokenPrice = (
    farm: SerializedFarm,
    quoteTokenFarm: SerializedFarm,
    bnbPriceBusd: BigNumber,
): BigNumber => {
    if (farm.quoteToken.symbol === 'BUSD') {
        return BIG_ONE
    }

    if (farm.quoteToken.symbol === 'WBNB') {
        return bnbPriceBusd
    }

    if (!quoteTokenFarm) {
        return BIG_ZERO
    }

    if (quoteTokenFarm.quoteToken.symbol === 'WBNB') {
        return quoteTokenFarm.tokenPriceVsQuote ? bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
    }

    if (quoteTokenFarm.quoteToken.symbol === 'BUSD') {
        return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
    }

    return BIG_ZERO
}

const fetchFarmsPrices = async (farms: SerializedFarm[]) => {
    const bnbBusdFarm = farms.find((farm) => farm.pid === 252)
    const bnbPriceBusd = bnbBusdFarm?.tokenPriceVsQuote ? BIG_ONE.div(bnbBusdFarm.tokenPriceVsQuote) : BIG_ZERO

    const farmsWithPrices = farms.map((farm) => {
        const quoteTokenFarm = getFarmFromTokenSymbol(farms, farm.quoteToken.symbol)
        const tokenPriceBusd = getFarmBaseTokenPrice(farm, quoteTokenFarm, bnbPriceBusd)
        const quoteTokenPriceBusd = getFarmQuoteTokenPrice(farm, quoteTokenFarm, bnbPriceBusd)

        return {
            ...farm,
            tokenPriceBusd: tokenPriceBusd.toJSON(),
            quoteTokenPriceBusd: quoteTokenPriceBusd.toJSON(),
        }
    })

    return farmsWithPrices
}

export default fetchFarmsPrices
