import React, { useState } from 'react'
import {
    ButtonMenu,
    ButtonMenuItem,
    CloseIcon,
    Heading,
    IconButton,
    InjectedModalProps,
    ModalBody,
    ModalContainer,
    ModalHeader as UIKitModalHeader,
    ModalTitle,
} from 'fortcake-uikit-v2'
import { parseUnits } from 'ethers/lib/utils'
import { useTranslation } from '../../../contexts/Localization'
import styled from 'styled-components'
import { FetchStatus, useGetBnbBalance } from '../../../hooks/useTokenBalance'
import useTheme from '../../../hooks/useTheme'
import WalletInfo from './WalletInfo'
import WalletTransactions from './WalletTransactions'

export enum WalletView {
    WALLET_INFO,
    TRANSACTIONS,
}

interface WalletModalProps extends InjectedModalProps {
    initialView?: WalletView
}

export const LOW_BNB_BALANCE = parseUnits('2', 'gwei')

const ModalHeader = styled(UIKitModalHeader)`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
`

const Tabs = styled.div`
  background-color: ${({ theme }) => theme.colors.dropdown};
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  padding: 16px 24px;
`

const WalletModal: React.FC<WalletModalProps> = ({ initialView = WalletView.WALLET_INFO, onDismiss }) => {
    const [view, setView] = useState(initialView)
    const { t } = useTranslation()
    const { balance, fetchStatus } = useGetBnbBalance()
    const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)
    const { isDark } = useTheme()

    const handleClick = (newIndex: number) => {
        setView(newIndex)
    }

    return (
        <ModalContainer title={t('Welcome!')} minWidth="320px">
            <ModalHeader>
                <ModalTitle>
                    <Heading>{t('Your Wallet')}</Heading>
                </ModalTitle>
                <IconButton variant="text" onClick={onDismiss}>
                    <CloseIcon width="24px" color="text" />
                </IconButton>
            </ModalHeader>
            <Tabs>
                <ButtonMenu
                    scale="sm"
                    variant={isDark ? 'subtle' : 'primary'}
                    onItemClick={handleClick}
                    activeIndex={view}
                    fullWidth
                >
                    <ButtonMenuItem>{t('Wallet')}</ButtonMenuItem>
                    <ButtonMenuItem>{t('Transactions')}</ButtonMenuItem>
                </ButtonMenu>
            </Tabs>
            <ModalBody p="24px" maxWidth="400px" width="100%">
                {view === WalletView.WALLET_INFO && <WalletInfo hasLowBnbBalance={hasLowBnbBalance} onDismiss={onDismiss} />}
                {view === WalletView.TRANSACTIONS && <WalletTransactions />}
            </ModalBody>
        </ModalContainer>
    )
}

export default WalletModal
