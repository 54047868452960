import { ConfigMenuItemsType } from './config'

export const getActiveMenuItem = ({ pathname, menuConfig }: { pathname: string; menuConfig: ConfigMenuItemsType[] }) =>
    menuConfig.find((menuItem) => pathname.startsWith(menuItem.href) || getActiveSubMenuItem({ menuItem, pathname }))

export const getActiveSubMenuItem = ({ pathname, menuItem }: { pathname: string; menuItem?: ConfigMenuItemsType }) => {
    const activeSubMenuItems = menuItem?.items?.filter((subMenuItem) => pathname.startsWith(subMenuItem.href as string)) ?? []

    // Pathname doesn't include any submenu item href - return undefined
    if (!activeSubMenuItems || activeSubMenuItems.length === 0) {
        return undefined
    }

    // Pathname includes one sub menu item href - return it
    if (activeSubMenuItems.length === 1) {
        return activeSubMenuItems[0]
    }

    // Pathname includes multiple sub menu item hrefs - find the most specific match
    const mostSpecificMatch = activeSubMenuItems.sort(
        (subMenuItem1, subMenuItem2) => (subMenuItem2.href?.length || 0) - (subMenuItem1.href?.length || 0),
    )[0]

    return mostSpecificMatch
}
