import React from 'react'
import { Price } from 'fortcake-sdk'
import { AutoRenewIcon } from 'fortcake-uikit-v2'
import { Text, StyledBalanceMaxMini } from './styleds'

interface TradePriceProps {
    price?: Price
    showInverted: boolean
    setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
    const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

    const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
    const label = showInverted
        ? `${price?.quoteCurrency?.symbol} per ${price?.baseCurrency?.symbol}`
        : `${price?.baseCurrency?.symbol} per ${price?.quoteCurrency?.symbol}`

    return (
        <Text $contrast={2} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            {show ? (
                <>
                    {formattedPrice ?? '-'} {label}
                    <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
                        <AutoRenewIcon width="14px" />
                    </StyledBalanceMaxMini>
                </>
            ) : (
                '-'
            )}
        </Text>
    )
}
